import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

import { IPagination } from '../../core/models/api/pagination/pagination.model';
import { Property } from '../../models';

export const featureAdapter: EntityAdapter<Property> =
  createEntityAdapter<Property>({
    selectId: (model) => model.id,
  });

export interface State extends EntityState<Property> {
  isLoading?: boolean;
  error?: any;
  pagination: IPagination;
  propertyIdSelected: number;
}

export const initialState: State = featureAdapter.getInitialState({
  isLoading: false,
  error: null,
  pagination: null,
  propertyIdSelected: null,
});
