import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { PropertiesStoreEffects } from './effects';
import { propertiesReducer } from './reducer';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature('properties', propertiesReducer),
    EffectsModule.forFeature([PropertiesStoreEffects]),
  ],
})
export class PropertiesStoreModule {}
